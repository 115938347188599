<template>
  <div style="margin: 20px 0">
    <el-row>
      <el-col :span="16">
        <el-button
          size="small"
          type="primary"
          @click="showFilter = !showFilter"
        >
          Filter
        </el-button>
        <el-button
          size="small"
          type="success"
          @click="showConfirmationDialog = true"
          :disabled="exportLoading === true || listData.length < 1"
        >
          <i class="el-icon-loading" v-if="exportLoading === true"></i> Export
          File
        </el-button>
      </el-col>
    </el-row>
  </div>
  <!-- Filter Form -->
  <div class="desktop-content">
    <div style="margin: 20px 0" v-if="showFilter">
      <el-form>
        <el-card shadow="hover">
          <el-row :gutter="30">
            <el-col :span="5">
              <el-form-item label="Target Order ID">
                <el-input v-model="orderId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="Hub Order ID">
                <el-input v-model="hubId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Status">
                <el-select
                  style="width: -webkit-fill-available"
                  filterable
                  v-model="condition.completed"
                  placeholder="Search"
                >
                  <el-option
                    v-for="item in listStatus"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="6">
              <el-button size="small" type="success" @click="getSearchData()">
                Search
              </el-button>
              <el-button size="small" type="primary" @click="handleClear()">
                Clear
              </el-button>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
  <div class="mobile-content">
    <div style="margin: 20px 0" v-if="showFilter">
      <el-form>
        <el-card shadow="hover">
          <el-row :gutter="30">
            <el-form-item label="Target Order ID">
              <el-input v-model="orderId" style="width: 300px" />
            </el-form-item>

            <el-form-item label="Hub Order ID">
              <el-input v-model="hubId" style="width: 300px" />
            </el-form-item>

            <el-form-item label="Status">
              <el-select
                style="width: 300px"
                filterable
                v-model="condition.completed"
                placeholder="Search"
              >
                <el-option
                  v-for="item in listStatus"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row :gutter="30">
            <el-button size="small" type="success" @click="getSearchData()">
              Search
            </el-button>
            <el-button size="small" type="primary" @click="handleClear()">
              Clear
            </el-button>
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
  <!-- Table -->
  <div class="desktop-content">
    <el-table
      :data="listData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="44"> </el-table-column>
      <el-table-column label="Target Order ID">
        <template #default="scope">
          <span v-if="scope.row['target_id']"> {{ scope.row['target_id'] }}</span>
          <span v-if="!scope.row['target_id']">
            <template v-for="orderId in targetOrderIds" :key="orderId">
              <template
                v-if="scope.row[orderId] != undefined && scope.row[orderId] != ''"
              >
                {{ scope.row[orderId] }}</template
              >
            </template>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Hub Order ID">
        <template #default="scope">
          {{ scope.row['hub_id'] || scope.row[hubOrderId] }}
        </template>
      </el-table-column>
      <el-table-column label="Target Name">
        <template #default="scope">
          <template v-if="scope.row.connectionTarget">
            {{ scope.row.connectionTarget.name }}</template
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="dateCreated" label="Date Created">
       <template #default="scope">
        {{ new Date(scope.row.dateCreated).toLocaleString() }}
      </template>
    </el-table-column> -->
      <!-- <el-table-column prop="dateUpdated" label="Last Updated">
       <template #default="scope">
        {{ new Date(scope.row.dateUpdated).toLocaleString() }}
      </template>
    </el-table-column> -->
      <el-table-column label="Status" prop="completed">
        <template #default="scope">
          <div
            v-if="
              scope.row.completed &&
              (!scope.row.history || !scope.row.history.length)
            "
            size="medium"
          >
            <el-tooltip
              class="item"
              effect="light"
              content="Completed"
              placement="right"
            >
              <el-button
                type="success"
                icon="el-icon-check"
                circle
                size="small"
              ></el-button>
            </el-tooltip>
          </div>
          <div v-else>
            <el-tooltip
              class="item"
              effect="light"
              content="Failed"
              placement="right"
            >
              <el-button
                v-if="scope.row.numberOfRetry < 5"
                type="warning"
                icon="el-icon-close"
                circle
                size="small"
              ></el-button>
              <el-button
                v-else
                type="danger"
                icon="el-icon-close"
                circle
                size="small"
              ></el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Number Of Retry" prop="history">
          <template #default="scope">
             <div >
                 <p>{{scope.row.numberOfRetry}}</p>
             </div>
            </template>
        </el-table-column> -->
      <el-table-column label="Timestamp" prop="timeStamp"></el-table-column>
      <el-table-column fixed="right" label="Inspect" width="120">
        <template #default="scope">
          <el-tooltip
            class="item"
            effect="light"
            content="Detail"
            placement="right"
          >
            <el-button
              size="small"
              @click="viewDetail(scope.row)"
              icon="el-icon-document"
              type="primary"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="mobile-content">
    <div v-if="listData">
      <el-card
        v-for="(item, index) in listData"
        :key="index"
        style="margin: 10px 0; padding: 5px 0"
        @click="viewDetail(item)"
      >
        <div>
          <span>Target Order ID:</span>
          <span v-if="item['target_id']"> {{ item['target_id'] }}</span>
          <span v-if="!item['target_id']">
            <template v-for="orderId in targetOrderIds" :key="orderId">
              <template v-if="item[orderId] != undefined && item[orderId] != ''">
                {{ item[orderId] }}</template
              >
            </template>
          </span>
        </div>
        <div>
          <p>Hub Order ID: {{ item['hub_id'] || item[hubOrderId] }}</p>
          <p>
            Target Name:
            {{ item.connectionTarget ? item.connectionTarget.name : '' }}
          </p>
          <p>
            Status:
            <el-button
              v-if="item.completed && (!item.history || !item.history.length)"
              type="success"
              icon="el-icon-check"
              circle
              size="small"
            ></el-button>
            <el-button
              v-else-if="item.numberOfRetry < 5"
              type="warning"
              icon="el-icon-close"
              circle
              size="small"
            ></el-button>
            <el-button
              v-else
              type="danger"
              icon="el-icon-close"
              circle
              size="small"
            ></el-button>
          </p>
          <label>Timestamp: {{ item.timeStamp }}</label>
        </div>
        <!-- <div>
          <el-button @click="viewDetail(item)" type="primary" size="small">
            Inspect
          </el-button>
        </div> -->
      </el-card>
    </div>
    <div v-else style="color: #08209a; text-align: center">
      <h3>No Data</h3>
    </div>
  </div>

  <!-- Dialog popup -->
  <div class="desktop-content">
    <el-dialog title="Order Detail" v-model="dialogOrderDetail">
      <order-detail-modal :item="dataItem" />
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogOrderDetail = false"
            >Cancel</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
  <div class="mobile-content">
    <el-dialog title="Order Detail" v-model="dialogOrderDetail" fullscreen>
      <order-detail-modal :item="dataItem" />
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogOrderDetail = false"
            >Cancel</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>

  <el-dialog
    title="Warning"
    v-model="showConfirmationDialog"
    width="95%"
    @close="showConfirmationDialog = false"
  >
    <span>Do you confirm to export all selected. Continue?</span>
    <div style="margin-top: 15px">
      <span class="dialog-footer">
        <el-button @click="showConfirmationDialog = false">No</el-button>
        <el-button type="success" @click="handleConfirm">Yes</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'
import OrderDetailModal from './OrderDetailModal.vue'
import { ElMessageBox, ElMessage } from 'element-plus'

@Options({
  components: {
    OrderDetailModal
  },
  props: {
    listData: []
  }
})
export default class OrderTable extends Vue {
  name = 'Order Table'
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  dataItem = {}
  dialogOrderDetail = false

  viewDetail(row) {
    this.dataItem = row
    this.dialogOrderDetail = true
  }
  connectData = {}
  hubOrderId = ''
  targetOrderIds = []
  target = ''
  showFilter = false
  orderId = ''
  hubId = ''
  condition = {
    completed: undefined
  }
  listStatus = [
    { label: 'All', value: undefined },
    { label: 'Completed', value: true },
    { label: 'Failed', value: false }
  ]
  multipleSelection = []
  exportLoading = false
  showConfirmationDialog = false

  async created() {
    await this.getListConnect()
    const targetOrderId = this.$route.query.targetOrderId
    if (targetOrderId) {
      this.orderId = targetOrderId
      this.getSearchData()
      this.$watch(
        'listData',
        (newVal) => {
          if (newVal.length && this.$route.query.targetOrderId) {
            this.$router.push('/orders')
            this.viewDetail(newVal[0])
          }
        },
        { immediate: true, deep: true }
      )
    }
  }
  async getListConnect() {
    let res = await axios.get('user/connect/list', {
      headers: this.headers
    })
    if (res.data) {
      this.connectData = res.data
      for (let connect of this.connectData) {
        if (connect.connectFrom) {
          let connectType = connect.type
          this.hubOrderId = connectType + 'OrderId'
        } else {
          if (this.targetOrderIds.indexOf(connect.type + 'OrderId') == -1) {
            this.targetOrderIds.push(connect.type + 'OrderId')
          }
        }
      }
    }
  }
  async getSearchData() {
    this.condition['$or'] = undefined
    this.condition['$and'] = undefined
    if (this.orderId.length > 0 && this.hubId.length > 0) {
      this.condition['$and'] = [
        {
          $or: [
            {
              [this.hubOrderId]: { $regex: this.hubId, $options: 'i' }
            },
            {
              ['hub_id']: { $regex: this.hubId, $options: 'i' }
            },

          ]
        },
      ]
      for (let id of this.targetOrderIds) {
        this.condition['$and'][0]['$or'].push({
          [id]: { $regex: this.orderId, $options: 'i' }
        })
      }
      this.condition['$and'][0]['$or'].push({
        ['target_id']: { $regex: this.orderId, $options: 'i' }
      })
    } else {
      this.condition['$or'] = []
      if (this.orderId.length > 0) {
        for (let id of this.targetOrderIds) {
          this.condition['$or'].push({
            [id]: { $regex: this.orderId, $options: 'i' }
          })
        }
        this.condition['$or'].push({
          ['target_id']: { $regex: this.orderId, $options: 'i' }
        })
      }
      if (this.hubId.length > 0) {
        this.condition['$or'].push({
          [this.hubOrderId]: { $regex: this.hubId, $options: 'i' }
        })
        this.condition['$or'].push({
          ['hub_id']: { $regex: this.hubId, $options: 'i' }
        })
      }
      if (this.orderId.length == 0 && this.hubId.length == 0) {
        delete this.condition['$or']
      }
    }
    let data = {
      condition: this.condition
    }
    this.$emit('search', data)
  }
  handleClear() {
    this.condition.completed = undefined
    this.condition['$or'] = undefined
    this.condition['$and'] = undefined
    this.orderId = ''
    this.hubId = ''
    this.getSearchData()
  }
  handleSelectionChange(val) {
    this.multipleSelection = val
  }
  exportOrders() {
    ElMessageBox.confirm(
      'Do you confirm to export all selected. Continue?',
      'Warning',
      {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'success'
      }
    )
      .then(async () => {
        this.exportLoading = true
        this.headers.connectionTo = this.$store.state['targetConnection']
        let multipleSelectionCondition = { $or: [] }
        if (this.multipleSelection.length > 0) {
          this.condition['$or'] = []
          for (let item of this.multipleSelection) {
            let itemTargetId = ''
            for (let type of this.targetOrderIds) {
              if (item[type] !== null) {
                itemTargetId = type
                break
              }
            }
            if (itemTargetId != '') {
              multipleSelectionCondition['$or'].push({
                [itemTargetId]: { $regex: item[itemTargetId], $options: 'i' }
              })
            }
          }
        }
        if (
          this.condition.completed === true ||
          this.condition.completed === false
        )
          multipleSelectionCondition.completed = this.condition.completed
        if (this.condition['$and'] && this.condition['$and'].length)
          multipleSelectionCondition['$and'] = this.condition['$and']
        if (this.condition['$or'] && this.condition['$or'].length) {
          multipleSelectionCondition['$or'].push(...this.condition['$or'])
        }
        let attributeList = Object.keys(this.listData[0])
        let uniqueAttributes = new Set([
          this,
          this.hubOrderId,
          ...this.targetOrderIds,
          ...attributeList
        ])
        delete uniqueAttributes.__v
        let selectAttributes = [...uniqueAttributes]
        selectAttributes.shift()
        selectAttributes = selectAttributes.join(' ')
        let res = await axios
          .post('user/order/export', {
            headers: this.headers,
            condition: multipleSelectionCondition,
            select: selectAttributes
          })
          .catch((err) => console.log('[ERROR]', err))
        if (res) {
          const linkFileExport = (await res.data.linkFileExport) || ''
          if (
            linkFileExport != undefined ||
            linkFileExport != null ||
            linkFileExport != ''
          ) {
            // window.location = linkFileExport;
            ElMessageBox.alert(
              'The export file will be send to the email addresses in Notifications section. Please check your mailbox after few minutes.',
              'Notification',
              {
                confirmButtonText: 'OK',
                type: 'success',
                center: true
              }
            )

            this.exportLoading = false
          } else {
            ElMessageBox.alert('Error!!', 'Notification', {
              confirmButtonText: 'Close',
              type: 'warning',
              center: true
            })
          }
        }
      })
      .catch(() => console.log('null'))
  }

  async handleConfirm() {
    this.exportLoading = true
    this.headers.connectionTo = this.$store.state['targetConnection']
    let multipleSelectionCondition = { $or: [] }
    if (this.multipleSelection.length > 0) {
      this.condition['$or'] = []
      for (let item of this.multipleSelection) {
        let itemTargetId = ''
        for (let type of this.targetOrderIds) {
          if (item[type] !== null) {
            itemTargetId = type
            break
          }
        }
        if (itemTargetId != '') {
          multipleSelectionCondition['$or'].push({
            [itemTargetId]: { $regex: item[itemTargetId], $options: 'i' }
          })
        }
      }
    }
    if (this.condition.completed === true || this.condition.completed === false)
      multipleSelectionCondition.completed = this.condition.completed
    if (this.condition['$and'] && this.condition['$and'].length)
      multipleSelectionCondition['$and'] = this.condition['$and']
    if (this.condition['$or'] && this.condition['$or'].length) {
      multipleSelectionCondition['$or'].push(...this.condition['$or'])
    }
    let attributeList = Object.keys(this.listData[0])
    let uniqueAttributes = new Set([
      this,
      this.hubOrderId,
      ...this.targetOrderIds,
      ...attributeList
    ])
    delete uniqueAttributes.__v
    let selectAttributes = [...uniqueAttributes]
    selectAttributes.shift()
    selectAttributes = selectAttributes.join(' ')
    let res = await axios
      .post('user/order/export', {
        headers: this.headers,
        condition: multipleSelectionCondition,
        select: selectAttributes
      })
      .catch((err) => console.log('[ERROR]', err))
    if (res) {
      const linkFileExport = (await res.data.linkFileExport) || ''
      if (
        linkFileExport != undefined ||
        linkFileExport != null ||
        linkFileExport != ''
      ) {
        // window.location = linkFileExport;
        ElMessageBox.alert(
          'The export file will be send to the email addresses in Notifications section. Please check your mailbox after few minutes.',
          'Notification',
          {
            confirmButtonText: 'OK',
            type: 'success',
            center: true
          }
        )

        this.exportLoading = false
      } else {
        ElMessageBox.alert('Error!!', 'Notification', {
          confirmButtonText: 'Close',
          type: 'warning',
          center: true
        })
      }
    }
  }
}
</script>
<style scoped>
/* Styles for larger screens */
.desktop-content {
  display: block;
}

/* Styles for mobile screens */
.mobile-content {
  display: none;
}

@media screen and (max-width: 600px) {
  .desktop-content {
    display: none;
  }

  .mobile-content {
    display: block;
  }
}
</style>
