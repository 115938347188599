
import { Options, Vue } from 'vue-class-component'
import OrderTable from '../components/OrderTable.vue'
import axios from '@/plugins/axios'
interface Result {
  total: number
  data: []
}
@Options({
  components: {
    OrderTable
  }
})
export default class OrderMain extends Vue {
  name = 'Order'
  listOrder = []
  currentPage = 1
  totaRows = 0
  perPage = 20
  filter = {}
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  async created() {
    if (!this.$route.query.targetOrderId) {
      this.getListOrder(0)
    }
  }
  async getListOrder(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result = {
      total: 0,
      data: []
    }
    res = await axios.get('user/order/list', {
      headers: this.headers,
      params: {
        condition: this.filter,
        account: localStorage.getItem('account'),
        page: page,
        limit: this.perPage
      }
    })
    if (res) {
      this.totaRows = res.total
      this.listOrder = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.getListOrder(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.getListOrder(this.currentPage)
  }
  handleSearchOrder(data: any) {
    this.filter = data.condition
    this.getListOrder(0)
  }
}
